import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyone.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: this.props.heightTwo,
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className="case-study-one-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src="https://www.meritmile.com/uploads/2018/11/swapalease-logo.jpg" className="client-logo" width="238" height="27" alt="" />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div className="first-paragraph">
                            <p>Swapalease.com is the world’s largest automotive lease marketplace and the pioneer of facilitating lease transfers online, as evidenced by the company’s patented processes. Conceptualized in 1997, it has evolved into a leading automotive consumer online destination servicing all aspects of the automotive leasing lifecycle including education, research, pricing, acquisition and lease transfer.</p>
                        </div>
                    </div>
                    <div className="col-12">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph">
                            <h3>Challenge</h3>
                            <p>Since 1997 there have been effectively zero changes in how the lease marketplace functions. This lack of freshness translated to a lack of buzz and low consumer awareness. Dealership participation suffered and inventories of available cars in the marketplace were reduced. Merit Mile’s challenge was clear: reinvigorate the lease transfer category while strengthening the Swapalease.com leadership position.</p>
                        </div>
                        <div className="third-paragraph">
                            <h3>Solutions</h3>
                            <p>Merit Mile created an integrated Public Relations program guided by research and a messaging framework. A steady blend of trend-based, and creative story angles were used to increase share of voice in the marketplace and elevate Swap-a-lease as expert on the automotive leasing industry.</p>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src="https://www.meritmile.com/uploads/2018/11/swapalease-desktop.jpg" className="desktop-image img-fluid" width="623" height="512" alt="" />
                    </div>
                    <div className="col-12 order-3">
                        <div className="fourth-paragraph">
                            <h3>Results</h3>
                            <p>With over $3 billion of vehicles listed, Swapalease.com is now the largest automotive lease marketplace. Millions of buyers and sellers visit Swapalease.com each year to acquire, sell, and trade leased automobiles driven mainly by editorial pick-ups in mainstream media, as well as highly targeted automotive outlets.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitleOne">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div className="container single-video-text-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2018/11/swapalease-video-one-1.jpg" className="img-fluid" width="673" height="378" alt="" />
                        <div></div>
                    </div>
                    <div className="col-md-6 text">
                        <div>
                            <h3>Deliverables</h3>
                            <ul>
                                <li>Continuous Market &amp; Category Research</li>
                                <li>Ongoing Story Angle Ideation</li>
                                <li>Messaging, Strategy &amp; Positioning</li>
                                <li>Content Marketing</li>
                                <li>Editorial &amp; Media Relations</li>
                                <li>Media Training &amp; Media Tours</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-video-text-wrapper">
                <div className="row">
                    <div className="col-md-6 order-last">
                        <img src="https://www.meritmile.com/uploads/2018/11/swapalease-video-two-1.jpg" className="img-fluid" width="673" height="379" alt="" />
                        <div></div>
                    </div>
                    <div className="col-md-6 text">
                        <div>
                            <h3 className="swapalease">"Storytelling reveals meaningful truths, and truth sells"</h3>
                        </div>
                    </div>
                </div>
            </div>
            <OneImageFullTwo width={pageContext.childWordPressAcf1ImageFull.image.media_details.width/2} heightTwo={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2} imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} backgroundColor={pageContext.childWordPressAcf1ImageFull.image_full_bg_color} />
            <div className="container view-website-btn">
                <div className="row">
                    <div className="col-12 text-center">
                        <a href="http://www.swapalease.com/" target="_blank" rel="noopener noreferrer">
                            <div className="btn btn-black">View Website</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>"A fresh start can recharge a stale marketplace"</p>
                    </div>
                </div>
            </div>
        
        </div>
    </Layout>
)